import React from 'react';
import { ExternalLink } from '../../components';

const SpanishCookiePolicy = () => {
    return (
        <>
            <p className="lastUpdated">Last updated: January 1, 2024</p>
            <h2>1. Introduction</h2>
            <p>
                Welcome to regenera.earth ("we," "us," or "our"). We are committed to protecting
                your personal information and respecting your privacy. This Cookie Policy explains
                how we use cookies and similar technologies on our website,
                <ExternalLink
                    href={`https://${process.env.REACT_APP_BASE_URL}`}
                    className="green-link flex items-center"
                >
                    {process.env.REACT_APP_BASE_URL}
                </ExternalLink>
                ("Website"). By using our Website, you agree to the use of cookies as outlined in
                this policy.
            </p>
            <h2>2. Understanding Cookies</h2>
            <p>
                Cookies are small data files sent from a website's server to your web browser and
                stored on your device. Each time you visit a page on our site, your browser sends
                this data back to our server. This process helps us recognize your browser and track
                your activity on our website.
            </p>
            <h4>Types of Cookies We Use</h4>
            <ul>
                <li>
                    Session Cookies: These cookies are temporary and are erased when you close your
                    browser. They allow us to track your actions during a single browsing session,
                    making navigation across our site more seamless.
                </li>
                <li>
                    Persistent Cookies: These remain on your device even after you close your
                    browser. Persistent cookies help our website remember your preferences and
                    recognize you on subsequent visits, so you don't have to re-enter information
                    each time.
                </li>
            </ul>
            <h2>3. Managing Cookies</h2>
            <p>
                Session cookies are deleted when you exit your browser, while persistent cookies
                stay on your device until you remove them manually or they expire after a set
                period.
            </p>
            <p>
                Most web browsers offer settings that let you refuse cookies or delete them. You can
                usually find these options in your browser's "Settings" or "Preferences" menu. For
                example, in Internet Explorer, you can disable all cookies by navigating to "Tools{' '}
                {'>'}
                Internet Options {'>'} Privacy" and adjusting the slider to "Block all cookies."
                Keep in mind that disabling cookies may reduce the functionality of our website and
                affect your user experience.
            </p>
            <h2>4. How We Use Cookies</h2>
            <p>We use cookies to: </p>
            <ul>
                <li>
                    Ensure Website Functionality: Enable core functionalities such as security,
                    network management, and accessibility.
                </li>
                <li>Enhance User Experience: Remember your preferences and personalize content.</li>
                <li>
                    Analyze Performance: Understand how visitors interact with our Website to
                    improve functionality and user experience.
                </li>
            </ul>
            <h2>5. Types of Cookies We Use</h2>
            <ul>
                <li>
                    Essential Cookies: Necessary for the operation of our Website. They include, for
                    example, cookies that enable you to log into secure areas.
                </li>
                <li>
                    Analytical/Performance Cookies: Allow us to recognize and count the number of
                    visitors and see how visitors move around our Website.
                </li>
                <li>
                    Functionality Cookies: Used to recognize you when you return to our Website and
                    personalize content for you.
                </li>
                <li>
                    Targeting Cookies: Record your visit to our Website, pages visited, and links
                    followed to make our Website more relevant to your interests.
                </li>
                <li>
                    Social Media Cookies: Our website includes social media features, such as the
                    Facebook "Like" button, Twitter "Tweet" button, and LinkedIn "Share" button.
                    These features may collect your IP address, which page you are visiting on our
                    site, and may set a cookie to enable the feature to function properly.
                </li>
            </ul>
            <h2>6. Third-Party Cookies</h2>
            <p>
                We may use third-party services like Google Analytics for analytical purposes. These
                third parties may set their own cookies on your device. We do not control the use of
                these cookies and advise you to check the third-party websites for more information
                about their cookies and how to manage them.
            </p>
            <p>Other Third-Party Cookies</p>
            <ul>
                <li>
                    Facebook: We use Facebook plugins to allow you to share content. Facebook may
                    set cookies on your device and collect data according to their Data Policy.
                </li>
                <li>
                    Twitter: Twitter widgets enable content sharing and may place cookies on your
                    device. For more information, see Twitter's Privacy Policy.
                </li>
                <li>
                    LinkedIn: LinkedIn sharing buttons may collect data and set cookies. Please
                    review LinkedIn's Privacy Policy for details.
                </li>
            </ul>
            <p>Your Choices</p>
            <p>
                {' '}
                You can manage or disable social media cookies through your browser settings or by
                adjusting your preferences on the respective social media platforms. Please note
                that disabling these cookies may affect the functionality of the sharing features on
                our website.
            </p>
            <h2>7. Your Choices Regarding Cookies</h2>
            <p>Managing Cookies in Your Browser</p>
            <p>
                Most web browsers allow you to control cookies through their settings preferences.
                However, disabling cookies may affect the functionality of our Website.
            </p>
            <h2>8. Changes to This Cookie Policy</h2>
            <p>
                We may update this Cookie Policy from time to time. Any changes will be effective
                when we post the revised policy on our Website. Please check this page periodically
                for updates.
            </p>
            <h2>9. Contact Us</h2>
            <p>
                If you have any questions or concerns about our use of cookies, please contact us:
            </p>
            <p>
                Email:{' '}
                <ExternalLink
                    href="mailto:community@regenera.earth"
                    className="green-link flex items-center"
                >
                    {process.env.REACT_APP_REPLY_TO_EMAIL_ADDRESS}
                </ExternalLink>{' '}
                Address: Av. El Sol 627-B. Of. 404 Cusco, Perú
            </p>
            <h2>10. Consent</h2>
            <p>
                By continuing to use our Website without changing your settings, you consent to our
                use of cookies as described in this policy.
            </p>
        </>
    );
};

export default SpanishCookiePolicy;
