import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import { Page } from '../../components';
import { TabNav } from '../../components';

import config from '../../config';
import Header from '../NewLandingPage/Sections/Header/Header';
import Footer from '../NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';
import bgGreenTexture from '../../assets/newAssets/bg-texture-green.jpg';
import imageD from '../../assets/newAssets/icons/blog-title-line-d.svg';
import imageM from '../../assets/newAssets/icons/blog-title-line-m.svg';
import SpanishCookiePolicy from './CookiePolicy.es';
import EnglishCookiePolicy from './CookiePolicy.en';

import { TitleContainer, Center, MainContainer, SideBarNav, MainContent } from './Styles';

const CookiePolicyPageComponent = ({ location, history, user, intl }) => {
    const locale = intl && intl.locale;
    function FuncTranslate() {
        if (locale == 'en') {
            return <EnglishCookiePolicy />;
        } else {
            return <SpanishCookiePolicy />;
        }
    }

    const tabs = [
        {
            text: intl.formatMessage({ id: 'CookiePolicyPage.privacyTabTitle' }),
            selected: false,
            linkProps: {
                name: 'PrivacyPolicyPage',
            },
        },
        {
            text: intl.formatMessage({ id: 'CookiePolicyPage.tosTabTitle' }),
            selected: false,
            linkProps: {
                name: 'TermsOfServicePage',
            },
        },
        {
            text: intl.formatMessage({ id: 'CookiePolicyPage.cookieTabTitle' }),
            selected: true,
            linkProps: {
                name: 'CookiePolicyPage',
            },
        },
    ];
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'CookiePolicyPage.schemaTitle' }, { siteTitle });
    const schema = {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
    };
    return (
        <Page title={schemaTitle} schema={schema}>
            <Header currentPath={location.pathname} user={user} history={history} />

            <TitleContainer
                className="flex justify-center relative"
                bgGreenTexture={bgGreenTexture}
            >
                <Center className="mx-auto relative">
                    <div className="title-box text-center">
                        <h1>
                            <span className="border">
                                <FormattedMessage id="CookiePolicyPage.heading" />
                                <picture>
                                    <source media="(max-width:768px)" srcset={imageM} />
                                    <img src={imageD} className="w-auto" alt="Cookie Policy" />
                                </picture>
                            </span>
                        </h1>
                    </div>
                </Center>
            </TitleContainer>
            <MainContainer>
                <SideBarNav>
                    {tabs ? (
                        <TabNav rootClassName="tabs" tabRootClassName="tab" tabs={tabs} />
                    ) : null}
                </SideBarNav>
                <MainContent role="main">
                    <div className="content">
                        <FuncTranslate></FuncTranslate>
                    </div>
                </MainContent>
            </MainContainer>

            <Footer history={history} />
        </Page>
    );
};

const { bool } = PropTypes;

CookiePolicyPageComponent.propTypes = {
    scrollingDisabled: bool.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const user = state.user.currentUser
        ? {
              initials: state.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  state.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  state.user.currentUser.attributes.profile.lastName,
          }
        : null;
    return {
        user,
    };
};

const CookiePolicyPage = compose(
    withRouter,
    connect(mapStateToProps),
    injectIntl
)(CookiePolicyPageComponent);

export default CookiePolicyPage;
