import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../theme';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa6';
import { FaYoutube } from 'react-icons/fa';
import { injectIntl } from 'react-intl';
import { LightGreenBtn } from '../../../../newComponents/Button/ButtonSkin';
import LogoImageGreen from '../../../../assets/newAssets/logo-regenera.png';

import { ExternalLink } from '../../../../components';
import { FormattedMessage } from 'react-intl';
import * as coreAPI from '../../../../coreApi';
import {
    ConsentContainer,
    Checkbox,
    SubmitButtonContainer,
    Message,
    InputContainer,
    MainLinks,
    StyledButton,
    StyledLanguagesList,
    StyledListItem,
    StyledSuccessMessage,
    StyledCheckIcon,
    CheckboxContainer,
    ErrorCheckbox,
    IconContainer,
    StyledLocationPopper,
    Triangle,
    StyledLink,
    NavHeading,
    GridItem,
    FooterParent,
    FormContainer,
    Input,
    Form,
} from './Styles';
import { connect } from 'react-redux';
import { changeLanguage } from '../../../../ducks/Languages.duck';
import ReCAPTCHA from 'react-google-recaptcha';
const NewsletterCheckbox = styled.div`
    display: flex;
    align-items: center;
    margin-top: 12px;
    gap: 12px;

    label {
        color: var(--Trust-Green, #004d37);
        font: 400 16px/29px Montserrat, sans-serif;
    }

    input[type='checkbox'] {
        width: 26px;
        height: 26px;
        border: 1px solid rgba(221, 237, 208, 1);
        border-radius: 9px;
        background-color: var(--Clarity-White, #fff);
    }

    input[type='checkbox']:checked {
        background-color: red;
    }
`;
const capitalize = word => {
    return word.slice(0, 1).toUpperCase() + word.slice(1);
};

const getItems = item => [
    { text: <FormattedMessage id="NewLandingPage.footer.about" />, path: item.path },
    {
        text: <FormattedMessage id={`NewLandingPage.footer.become${capitalize(item.label)}`} />,
        path: `${item.path}#become`,
    },
    {
        text: <FormattedMessage id="NewLandingPage.footer.testimonials" />,
        path: `${item.path}#testimonials`,
    },
    // {
    //   text: <FormattedMessage id="NewLandingPage.footer.faqs" />,
    //   path: `${item.path}#faqs`,
    // },
];

const footerItems = [
    { label: 'natureLover', path: '/people' },
    { label: 'guardians', path: '/land-managers' },
    { label: 'partners', path: '/organizations' },
];

export const ErrorMessage = styled(Message)`
    color: ${colors.actionOrange};

    left: 0px;
    @media screen and (max-width: 425px) {
        top: 0px;
        left: 0px;
    }
`;

const mapStateToProps = storeState => ({
    languages: storeState.Languages.languages,
    selectedLanguage: storeState.Languages.selectedLanguage,
});

const Footer = connect(mapStateToProps, { changeLanguage })(
    ({ languages, changeLanguage, selectedLanguage, history, ...props }) => {
        const [nameError, setNameError] = useState('');
        const [emailError, setEmailError] = useState('');
        const [success, setSuccess] = useState(false);
        const [name, setName] = useState('');
        const [email, setEmail] = useState('');
        const [isChecked, setIsChecked] = useState(false);
        const [messageStatus, setmessageStatus] = useState();
        const [isLoading, setIsLoading] = useState(false);
        const [submitTry, setSubmitTry] = useState(false);
        const [checkboxError, setCheckboxError] = useState(false);

        const reCaptchaRef = React.createRef();

        const namePlaceholder = props.intl.formatMessage({
            id: 'NewLandingPage.footer.newsLetter.name.placeHolder',
        });
        useEffect(() => {
            if (isChecked) setCheckboxError(false);
        }, [isChecked]);

        const handleAllianceRedirect = slug => {
            history.push(`/user/${slug}`);
        };

        const handleNameChange = e => {
            setName(e.target.value);
            setNameError('');
            setmessageStatus('');
        };

        const handleEmailChange = e => {
            setEmail(e.target.value);
            setEmailError('');
            setmessageStatus('');
        };

        const currentLocale = props.intl.locale;

        const checkEmailSubscriptionCaptcha = async captchaToken => {
            console.log('Token', captchaToken);
            if (!!captchaToken && name !== '' && email !== '') {
                try {
                    console.log('in check email subscription');
                    await coreAPI.subscribeToNewsLetter(
                        {
                            firstname: name,
                            email,
                            language: currentLocale,
                            statusIfNew: 'subscribed',
                            status: 'subscribed',
                        },
                        captchaToken
                    );
                    setmessageStatus('sended');
                    setIsLoading(false);
                    setSuccess(true);
                } catch (e) {
                    setmessageStatus('error');
                    setIsLoading(false);
                }
            }
        };

        const validateEmail = email => {
            // Regular expression for basic email format validation
            const re = /.+@.+\.[A-Za-z]+$/;
            return re.test(email);
        };

        const handleNewsletter = async e => {
            e.preventDefault();

            if (name.trim() === '') {
                setNameError(true);
                setIsLoading(false);
                return;
            }
            if (email.trim() === '') {
                setEmailError(true);
                setIsLoading(false);
                return;
            }

            if (!validateEmail(email)) {
                setEmailError(true);
                setIsLoading(false);
                return;
            }

            if (isChecked === false) {
                setCheckboxError(true);
                setIsLoading(false);
                return;
            }
            if (!nameError && !emailError && isChecked == true) {
                setIsLoading(true);

                reCaptchaRef.current.execute();
                // try {
                //     await coreAPI.subscribeToNewsLetter({
                //         name,
                //         email,
                //         language: currentLocale,
                //         statusIfNew: 'subscribed',
                //         status: 'subscribed',
                //     });
                //     setmessageStatus('sended');
                //     setIsLoading(false);
                //     setSuccess(true);
                // } catch (e) {
                //     setmessageStatus('error');
                // }
            }
        };

        const SuccessMessage = (
            <StyledSuccessMessage>Thank you for signing up!</StyledSuccessMessage>
        );

        return (
            <FooterParent>
                <div className="footer-main flex items-start justify-start">
                    <div className="company-info flex flex-col justify-space-between items-end">
                        <div className="logo-info flex flex-col items-start justify-start">
                            <img src={LogoImageGreen} alt="logo" className="logo" />
                            <div className="power-info">
                                <p>
                                    <FormattedMessage id="NewLandingPage.footer.poweredBy" />,{' '}
                                    <br />{' '}
                                    <FormattedMessage id="NewLandingPage.footer.coordinatedBy" />{' '}
                                    <strong> Nature Services.</strong>
                                </p>
                            </div>
                        </div>
                        <div className="follow-us-parent flex flex-col justify-start items-start">
                            <div className="follow-us">
                                <FormattedMessage id="NewLandingPage.footer.followUs" />
                            </div>
                            <div className="social-icons-parent flex items-start justify-start">
                                <ExternalLink
                                    className="social-icons flex justify-center items-center"
                                    href="https://www.linkedin.com/showcase/regenera-peru/"
                                >
                                    <IconContext.Provider value={{ color: 'white' }}>
                                        <FaLinkedinIn />
                                    </IconContext.Provider>
                                </ExternalLink>

                                <ExternalLink
                                    className="social-icons flex justify-center items-center"
                                    href="https://www.facebook.com/regenera.peru"
                                >
                                    <IconContext.Provider value={{ color: 'white' }}>
                                        <FaFacebookF />
                                    </IconContext.Provider>
                                </ExternalLink>

                                <ExternalLink
                                    className="social-icons flex justify-center items-center"
                                    href="https://www.instagram.com/regenera.peru/"
                                >
                                    <IconContext.Provider value={{ color: 'white' }}>
                                        <FaInstagram />
                                    </IconContext.Provider>
                                </ExternalLink>

                                <ExternalLink
                                    className="social-icons flex justify-center items-center"
                                    href="https://www.youtube.com/@regenera_peru"
                                >
                                    <IconContext.Provider value={{ color: 'white' }}>
                                        <FaYoutube />
                                    </IconContext.Provider>
                                </ExternalLink>
                            </div>
                        </div>
                    </div>
                    <div className="footer-actions-parent flex items-start justify-space-between">
                        <div className="footer-actions flex flex-col items-start justify-start">
                            <div className="footer-action-head">
                                <FormattedMessage id="NewLandingPage.footer.getInTouch" />
                            </div>
                            <div className="footer-links flex flex-col items-start justify-start">
                                <ExternalLink href="https://calendly.com/frankhajek/30min">
                                    <FormattedMessage id="NewLandingPage.footer.scheduleCall" />
                                </ExternalLink>
                                <ExternalLink href="mailto:community@regenera.earth">
                                    <FormattedMessage id="NewLandingPage.footer.sendEmail" />
                                </ExternalLink>
                                <div>Av. El Sol 627-B. Of. 404 Cusco, Perú</div>
                            </div>
                        </div>
                        <div className="footer-actions flex flex-col items-start justify-start">
                            <div className="footer-action-head">
                                <FormattedMessage id="NewLandingPage.footer.joinMission" />
                            </div>
                            <div className="footer-links flex flex-col items-start justify-start">
                                <Link to="/organizations">
                                    <FormattedMessage id="NewLandingPage.footer.asOrganisation" />
                                </Link>
                                <Link to="/people">
                                    <FormattedMessage id="NewLandingPage.footer.asPerson" />
                                </Link>
                                <Link to="/land-managers">
                                    <FormattedMessage id="NewLandingPage.footer.asGuardian" />
                                </Link>
                            </div>
                        </div>
                        <div className="footer-actions flex flex-col items-start justify-start">
                            <div className="footer-action-head">
                                <FormattedMessage id="NewLandingPage.footer.learnMore" />
                            </div>
                            <div className="footer-links flex flex-col items-start justify-start">
                                <Link to="/">
                                    <FormattedMessage id="NewLandingPage.footer.home" />
                                </Link>
                                <Link to="/about">
                                    <FormattedMessage id="NewLandingPage.footer.about" />
                                </Link>
                                <Link to="/blog">Blog</Link>
                                <Link to="/faq">
                                    <FormattedMessage id="NewLandingPage.footer.faq" />
                                </Link>
                            </div>
                        </div>
                        <div className="footer-actions flex flex-col items-start justify-start">
                            <div className="footer-action-head subscrib-head">
                                <FormattedMessage id="NewLandingPage.footer.subscribeTitle" />
                            </div>

                            {!success ? (
                                <FormContainer>
                                    <Form
                                        onSubmit={handleNewsletter}
                                        className="flex flex-col items-center"
                                    >
                                        <div className="input-group w-full flex flex-col">
                                            <Input
                                                type="name"
                                                placeholder={namePlaceholder}
                                                value={name}
                                                onChange={handleNameChange}
                                                status={nameError && 'failure'}
                                            />

                                            <Input
                                                type="email"
                                                placeholder="Email"
                                                value={email}
                                                onChange={handleEmailChange}
                                                status={emailError && 'failure'}
                                            />
                                        </div>
                                        <div className="consent-check">
                                            <ConsentContainer>
                                                <CheckboxContainer>
                                                    {checkboxError ? (
                                                        <ErrorCheckbox
                                                            type="checkbox"
                                                            checked={isChecked}
                                                            onChange={() =>
                                                                setIsChecked(!isChecked)
                                                            }
                                                        />
                                                    ) : (
                                                        <Checkbox
                                                            type="checkbox"
                                                            checked={isChecked}
                                                            onChange={() =>
                                                                setIsChecked(!isChecked)
                                                            }
                                                        />
                                                    )}
                                                    <StyledCheckIcon
                                                        checked={isChecked}
                                                        onClick={() => setIsChecked(!isChecked)}
                                                    />
                                                </CheckboxContainer>
                                                <Message>
                                                    <FormattedMessage id="NewLandingPage.footer.consentMessage" />
                                                </Message>
                                            </ConsentContainer>
                                        </div>

                                        {messageStatus === 'error' && (
                                            <ErrorMessage>
                                                <FormattedMessage id="NewLandingPage.footer.errorMessage" />
                                            </ErrorMessage>
                                        )}
                                        {(nameError || emailError) && (
                                            <ErrorMessage>
                                                <FormattedMessage id="NewLandingPage.footer.validationMessage" />
                                            </ErrorMessage>
                                        )}

                                        <LightGreenBtn className="subscribe-btn" type="submit">
                                            <FormattedMessage id="NewLandingPage.footer.subscribe" />
                                        </LightGreenBtn>
                                    </Form>
                                </FormContainer>
                            ) : (
                                SuccessMessage
                            )}
                        </div>
                    </div>
                </div>
                <div className="footer-copyright-parent">
                    <div className="footer-copy-lists flex flex-row items-start justify-space-between">
                        <ul className="footer-copy-links flex flex-row items-start justify-start">
                            <li>&copy; Regenera, 2024</li>
                            <li>
                                <Link to="/terms-of-service">
                                    <FormattedMessage id="NewLandingPage.footer.terms" />
                                </Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy">
                                    <FormattedMessage id="NewLandingPage.footer.privacypolicy" />
                                </Link>
                            </li>
                            {/* <li>
                                <Link to="/sitemap">Site Map</Link>
                            </li> */}
                            <li>
                                <Link to="/cookie-policy">Cookies</Link>
                            </li>
                        </ul>
                        <ul className="footer-copy-links lang-foooter-link flex flex-row items-start justify-start">
                            {languages.map((item, index) => (
                                <li>
                                    {selectedLanguage &&
                                    selectedLanguage.name.toLowerCase() ===
                                        item.name.toLowerCase() ? (
                                        item.name
                                    ) : (
                                        <StyledButton onClick={() => changeLanguage(item.short)}>
                                            {item.name}
                                        </StyledButton>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <ReCAPTCHA
                    ref={reCaptchaRef}
                    size="invisible"
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    onChange={checkEmailSubscriptionCaptcha}
                />
                <Triangle />
            </FooterParent>
        );
    }
);

export default injectIntl(Footer);
