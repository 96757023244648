import React, { useState, useRef } from 'react';
import Header from '../NewLandingPage/Sections/Header/Header';
import Footer from '../NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Page } from '../../components';

import HeroSection from '../../newComponents/HeroSection/HeroSection';
import { OrangeBtn } from '../../newComponents/Button/ButtonSkin';
import { Link } from 'react-router-dom';
import { TeamUpSection, MultiColAction } from './Styles';

import TeamUpHeroDesktop from '../../assets/newAssets/bg-team-up.webp';
import bgGreenTexture from '../../assets/newAssets/bg-texture-green.jpg';
import ModalFormBox from '../Landscapes/LandscapeProposal/ModalForm';

const TeamUpPage = ({ location, history, user }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const pragraph = ["Great, you'd like to take action!"];
    const dialogRef = useRef(null);

    const openDialog = () => {
        dialogRef.current.showModal();
    };

    const closeDialog = () => {
        if (!isSubmitting) {
            dialogRef.current.close();
        }
    };
    return (
        <Page>
            <Header currentPath={location.pathname} user={user} history={history} />
            <TeamUpSection bgGreenTexture={bgGreenTexture}>
                <HeroSection
                    title="How would you like to"
                    titleline="team up?"
                    titleclass="text-white"
                    paratext={pragraph}
                    paraclass="text-white small-title absolute"
                    heroBg="green-bg"
                    heroImgD={TeamUpHeroDesktop}
                    alttxt="TeamUp"
                />
                <MultiColAction bgGreenTexture={bgGreenTexture}>
                    <div className="green-bg">
                        <div className="wrapper w-full mx-auto flex">
                            <div className="col-box text-center">
                                <h3>Footprint measurements</h3>
                                <p>
                                    Looking for a footprint measurement tailored to your
                                    organisation? Engage with one of our expert footprint advisors.
                                </p>
                                <OrangeBtn className="w-full" onClick={openDialog}>
                                    Get in touch
                                </OrangeBtn>
                            </div>
                            <div className="col-box text-center">
                                <h3>Solve operational or sustainability challenges</h3>
                                <p>
                                    Need to solve an operational or sustainability challenge? Engage
                                    with one of our expert engineers.
                                </p>
                                <OrangeBtn className="w-full">
                                    <Link to="#">Get in touch</Link>
                                </OrangeBtn>
                            </div>
                            <div className="col-box text-center">
                                <h3>Positive Nature & climate actions</h3>
                                <p>
                                    Aiming to support positive Nature & climate actions? Connect and
                                    regenerate your footprint in a Regenera landscape.
                                </p>
                                <OrangeBtn className="w-full">
                                    <Link to="#">Get in touch</Link>
                                </OrangeBtn>
                            </div>
                        </div>
                    </div>
                </MultiColAction>
            </TeamUpSection>
            <dialog ref={dialogRef}>
                <ModalFormBox closeBtn={closeDialog} />
            </dialog>
            <Footer history={history} />
        </Page>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(TeamUpPage));
