import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import VisibilitySensor from 'react-visibility-sensor';

import {
    fetchLandscapes,
    fetchDescription,
} from '../../../../components/ChooseLandscape/fetchLandscapes';
import { LightGreenBtn } from '../../../../newComponents/Button/ButtonSkin';

import { Link } from 'react-router-dom';
import CustomSelect from '../../../../newComponents/SelectDropdown/SelectDropdown';
import ImpactSlider from 'react-slick';

import mapIcon from '../../../../assets/newAssets/icons/map-icon.svg';
import cotttonIcon from '../../../../assets/newAssets/icons/cotton-icon.svg';
import leafIcon from '../../../../assets/newAssets/icons/leaf-icon.svg';
import locationPin from '../../../../assets/newAssets/icons/location-pin.svg';
import locationPinSelected from '../../../../assets/newAssets/icons/orange-location-pin.svg';
import arrowPointer from '../../../../assets/newAssets/arrow-landscape.svg';
import peruMap from '../../../../assets/newAssets/peru-map.svg';
import bgGreenTexture from '../../../../assets/newAssets/bg-texture-green.jpg';
import actionImage from '../../../../assets/newAssets/image-35.png';
import thumbPrintGreen from '../../../../assets/newAssets/thumbprintGreen.svg';
import CustomToolTip from '../../../../newComponents/CustomToolTip/CustomToolTip';

import AOS from 'aos';
import { PositiveImpact } from './Styles';
import { FaHeartPulse } from 'react-icons/fa6';
import { getLatestBlogsByTag, handleQueryLanguage } from '../../../../newContainers/Blogs/utils';
import { useSelector } from 'react-redux';

const PartnersImpact = () => {
    const { selectedLanguage } = useSelector(storeState => storeState.Languages);

    const [landscapes, setLandscapes] = useState(null);
    const [description, setDescription] = useState(null);
    const [actions, setActions] = useState([]);
    const [selectedLandscape, setSelectedLandscape] = useState(null);

    const mapRef = useRef(null);
    const sliderRef = useRef(null);

    const [showPins, setShowPins] = useState(false);

    const getHighlightedText = data => {
        let temp = data.slice(4);
        const index = temp.indexOf('**');
        const highlighted = temp.slice(0, index);
        const normal = temp.slice(index + 2);
        return { text: normal, highlightedPart: highlighted };
    };
    const handlePinClick = id => {
        const index = landscapes && landscapes.findIndex(item => item.id === id);
        const filtered = landscapes && landscapes.filter(item => item.id === id);
        setSelectedLandscape(filtered[0]);
        sliderRef.current.slickGoTo(index);
    };

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
        window.addEventListener('load', AOS.refresh);
    }, []);

    const totalPins = 5; // Total number of pins

    useEffect(() => {
        fetchLandscapes()
            .then(resp => {
                if (resp) {
                    setLandscapes(resp);
                    setSelectedLandscape(resp[0]);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        if (selectedLandscape) {
            console.log('selectedlandscap', selectedLandscape);
            fetchDescription(selectedLandscape.id)
                .then(resp => {
                    setDescription(resp.description);
                })
                .catch(err => console.log(err));

            (async () => {
                const response = await getLatestBlogsByTag(
                    selectedLanguage.short.toLowerCase(),
                    selectedLandscape.titleSlug
                );
                if (response.status === 'success') {
                    console.log('response.data', response.data);
                    setActionData(response.data);
                }
            })();
        }
    }, [selectedLandscape]);

    const [data, setData] = useState([
        {
            id: 1,
            img: `${mapIcon}`,
        },
        {
            id: 2,
            img: `${cotttonIcon}`,
        },
        {
            id: 3,
            img: `${leafIcon}`,
        },
    ]);

    const [actionData, setActionData] = useState([]);

    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        centerMode: false,
        variableWidth: false,

        afterChange: currentSlide => {
            if (currentSlide === landscapes.length - 1) {
                console.log('pinfalse', showPins);
                setShowPins(false);
            }

            const objectId = landscapes && landscapes[currentSlide].id;
            const filtered = landscapes && landscapes.filter(item => item.id === objectId);
            console.log('currentSlide', currentSlide, filtered);
            setSelectedLandscape(filtered[0]);
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    fade: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: false,
                    speed: 300,
                    centerMode: true,
                    centerPadding: '35px',
                    infinite: true,
                    variableWidth: true,
                },
            },
        ],
    };
    const [visible, setVisible] = useState(false);

    const handleScroll = e => {
        console.log('comesinscroll');
        const impactListenerTop = window.scrollY;
        console.log(impactListenerTop);
        if (showPins) {
            e.preventDefault();
            e.stopPropagation();
            console.log('showpinstrue', showPins);
            sliderRef.current.slickNext();
        }
    };

    const changeHandler = e => {
        console.log('e', e);
        setVisible(e);
        console.log('isvi', e);
        if (e) {
            setShowPins(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        console.log('added listener');
        return () => {
            console.log('removed listener');
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showPins]);

    return (
        <PositiveImpact arrowPointer={arrowPointer} bgGreenTexture={bgGreenTexture}>
            <div className="peru-location-info mx-auto flex items-center">
                <div id="impactList" className="impact-list-info flex flex-col">
                    <div className="title-box">
                        <h3>
                            <FormattedMessage
                                id="NewLandingPage.partnersImpact.title"
                                values={{
                                    br: <br />,
                                }}
                            />
                        </h3>
                        <div className="custom-select-container">
                            {landscapes && (
                                <CustomSelect
                                    options={landscapes}
                                    handlePinClick={handlePinClick}
                                    selectedPin={(selectedLandscape && selectedLandscape.id) || 5}
                                />
                            )}
                        </div>
                    </div>

                    <div className="article-section flex items-start justify-space-between">
                        <div className="card-slider">
                            <VisibilitySensor
                                onChange={changeHandler}
                                scrollCheck={true}
                                active={!visible}
                            >
                                <ImpactSlider {...settings} ref={sliderRef}>
                                    {/* mobile view discovery card start */}
                                    {landscapes &&
                                        landscapes.map(landscape => (
                                            <div className="discover-card w-full ">
                                                <div className="card-img-btn">
                                                    <div className="card-img">
                                                        <picture>
                                                            {landscape.id === 6 && (
                                                                <div className="new-label">
                                                                    <FormattedMessage id="NewLandingPage.partnersImpact.newLabel" />
                                                                </div>
                                                            )}
                                                            <source
                                                                media="(max-width:700px)"
                                                                srcset={
                                                                    landscape &&
                                                                    landscape.sliderImage
                                                                }
                                                            />
                                                            <img
                                                                src={
                                                                    landscape &&
                                                                    landscape.sliderImage
                                                                }
                                                                className="w-full"
                                                                alt=""
                                                            />
                                                        </picture>
                                                    </div>
                                                    <LightGreenBtn className="card-button">
                                                        <Link
                                                            to={`/landscapes/${landscape.titleSlug}`}
                                                        >
                                                            <FormattedMessage id="NewLandingPage.partnersImpact.discoverMore" />
                                                        </Link>
                                                    </LightGreenBtn>
                                                </div>

                                                <div className="discover-card-info ">
                                                    <div className="card-title">
                                                        <FormattedMessage
                                                            id="NewLandingPage.ourLandscapes.landscapeTitle"
                                                            values={{
                                                                landscape: landscape.name,
                                                            }}
                                                        />
                                                    </div>
                                                    <p className="card-info">
                                                        <FormattedMessage
                                                            id="NewLandingPage.ourLandscapes.landscapeDesc"
                                                            values={{
                                                                description:
                                                                    landscape.metadata.description.indexOf(
                                                                        '.'
                                                                    ) === -1
                                                                        ? landscape.metadata
                                                                              .description
                                                                        : landscape.metadata.description
                                                                              .split('.')[0]
                                                                              .trim() + '.',
                                                            }}
                                                        />
                                                    </p>
                                                    <hr />
                                                    <div className="icon-with-numbers flex flex-col">
                                                        <ul>
                                                            {landscape.metadata.impact
                                                                .split('\n')
                                                                .map(itemSet =>
                                                                    getHighlightedText(itemSet)
                                                                )
                                                                .map((item, index) => (
                                                                    <li className="flex items-center">
                                                                        <div className="icon-box flex items-center justify-center">
                                                                            <img
                                                                                src={
                                                                                    data[index].img
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="number-info">
                                                                            <strong>
                                                                                {
                                                                                    item.highlightedPart
                                                                                }
                                                                            </strong>{' '}
                                                                            {item.text}
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                        <LightGreenBtn className="card-button-mobile">
                                                            <Link
                                                                to={`/landscapes/${landscape.titleSlug}`}
                                                            >
                                                                <FormattedMessage id="NewLandingPage.partnersImpact.discoverMore" />
                                                            </Link>
                                                        </LightGreenBtn>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    {/* mobile view discovery card ends*/}
                                </ImpactSlider>
                            </VisibilitySensor>
                        </div>

                        <div className="recent-actions">
                            <h6>
                                <FormattedMessage id="NewLandingPage.partnersImpact.recentActionsTitle" />
                                :
                            </h6>

                            <div className="list-recent-action flex flex-col">
                                {actionData && actionData.length > 0 ? (
                                    actionData &&
                                    actionData.map(item => (
                                        <Link
                                            to={`blog/${item.slug}`}
                                            className="article-thumb-section flex items-center"
                                        >
                                            <img src={item.blog_details.thumbnail} alt="" />
                                            <div className="action-content">
                                                <h6 className="article-title">{item.title}</h6>
                                                <div className="link-text">
                                                    <FormattedMessage id="NewLandingPage.partnersImpact.articleLinkText" />
                                                </div>
                                            </div>
                                        </Link>
                                    ))
                                ) : (
                                    <Link
                                        to={`blog`}
                                        className="article-thumb-section flex items-center"
                                    >
                                        <img src={thumbPrintGreen} className="placeholderImg" />
                                        <div className="action-content">
                                            <h6 className="article-title">
                                                <FormattedMessage id="NewLandingPage.partnersImpact.recentActionsComingText" />
                                            </h6>
                                            <div className="link-text">
                                                <FormattedMessage id="NewLandingPage.partnersImpact.articleLinkText" />
                                            </div>
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="peru-map" ref={mapRef} data-aos={'fade-left'}>
                    <img src={peruMap} alt="peru map" />

                    <>
                        <div
                            className="location top-one-location"
                            onClick={() => handlePinClick(3)}
                        >
                            <CustomToolTip label="Requena">
                                <img
                                    src={
                                        selectedLandscape && selectedLandscape.id === 3
                                            ? locationPinSelected
                                            : locationPin
                                    }
                                    alt="Requena"
                                />
                            </CustomToolTip>
                        </div>
                        <div
                            className="location center-two-location"
                            onClick={() => handlePinClick(4)}
                        >
                            <CustomToolTip label="Cañete">
                                <img
                                    src={
                                        selectedLandscape && selectedLandscape.id === 4
                                            ? locationPinSelected
                                            : locationPin
                                    }
                                    alt="Cañete"
                                />
                            </CustomToolTip>
                        </div>
                        <div
                            className="location bottom-three-location"
                            onClick={() => handlePinClick(5)}
                        >
                            <CustomToolTip label="Abancay">
                                <img
                                    src={
                                        selectedLandscape && selectedLandscape.id === 5
                                            ? locationPinSelected
                                            : locationPin
                                    }
                                />
                            </CustomToolTip>
                        </div>

                        <div
                            className="location bottom-four-location"
                            onClick={() => handlePinClick(2)}
                        >
                            <CustomToolTip label="Machu Picchu">
                                <img
                                    src={
                                        selectedLandscape && selectedLandscape.id === 2
                                            ? locationPinSelected
                                            : locationPin
                                    }
                                    alt="Machu Picchu"
                                />
                            </CustomToolTip>
                        </div>
                        <div
                            className="location bottom-five-location"
                            onClick={() => handlePinClick(1)}
                        >
                            <CustomToolTip label="Manu">
                                <img
                                    src={
                                        selectedLandscape && selectedLandscape.id === 1
                                            ? locationPinSelected
                                            : locationPin
                                    }
                                    alt="Manu"
                                />
                            </CustomToolTip>
                        </div>
                        <div
                            className="location bottom-six-location"
                            onClick={() => handlePinClick(6)}
                        >
                            <CustomToolTip label="Paucartambo">
                                <img
                                    src={
                                        selectedLandscape && selectedLandscape.id === 6
                                            ? locationPinSelected
                                            : locationPin
                                    }
                                    alt="Paucartambo"
                                />
                            </CustomToolTip>
                        </div>
                    </>

                    <div className="click-info">
                        <FormattedMessage id="NewLandingPage.partnersImpact.clickInfo" />
                    </div>
                </div>
            </div>
        </PositiveImpact>
    );
};

export default PartnersImpact;
