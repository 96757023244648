import React, { useState } from 'react';
import Header from '../NewLandingPage/Sections/Header/Header';
import Footer from '../NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Page } from '../../components';

import { Link } from 'react-router-dom';
import { SitemapSection } from './Styles';
import bgGreenTexture from '../../assets/newAssets/bg-texture-green.jpg';
import titleBorderLine from '../../assets/newAssets/title-border-line.svg';

const SiteMapPage = ({ location, history, user }) => {
    const pragraph = ["Great, you'd like to take action!"];
    return (
        <Page>
            <Header currentPath={location.pathname} user={user} history={history} />
            <SitemapSection bgGreenTexture={bgGreenTexture}>
                <div className="green-bg">
                    <div className="wrapper w-full mx-auto">
                        <div className="title-box">
                            <h1 className="text-white">
                                Regenera{' '}
                                <span className="border">
                                    Site Map
                                    <img src={`${titleBorderLine}`} alt="border" />
                                </span>
                            </h1>
                        </div>
                        <div className="col-box w-full flex-wrap flex">
                            <div className="list-col">
                                <div className="title-links">
                                    <h4 className="small-title">Join Regenera</h4>
                                    <ul className="sitemap-links">
                                        <li>
                                            <Link to="#">As an organisation</Link>
                                        </li>
                                        <li>
                                            <Link to="#">As a person</Link>
                                        </li>
                                        <li>
                                            <Link to="#">As a land manager</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="title-links">
                                    <h4 className="small-title">Regenera login</h4>
                                    <ul className="sitemap-links">
                                        <li>
                                            <Link to="#">login</Link>
                                        </li>
                                        <li>
                                            <Link to="#">signup</Link>
                                        </li>
                                        <li>
                                            <Link to="#">recover-password</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="title-links">
                                    <h4 className="small-title">Regenera login</h4>
                                    <ul className="sitemap-links">
                                        <li>
                                            <Link to="#">login</Link>
                                        </li>
                                        <li>
                                            <Link to="#">signup</Link>
                                        </li>
                                        <li>
                                            <Link to="#">recover-password</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="list-col">
                                <div className="title-links">
                                    <h4 className="small-title">About Regenera</h4>
                                    <ul className="sitemap-links">
                                        <li>
                                            <Link to="#">organizations</Link>
                                        </li>
                                        <li>
                                            <Link to="#">people</Link>
                                        </li>
                                        <li>
                                            <Link to="#">about</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="title-links">
                                    <h4 className="small-title">Regenera login</h4>
                                    <ul className="sitemap-links">
                                        <li>
                                            <Link to="#">login</Link>
                                        </li>
                                        <li>
                                            <Link to="#">signup</Link>
                                        </li>
                                        <li>
                                            <Link to="#">recover-password</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="title-links">
                                    <h4 className="small-title">Regenera login</h4>
                                    <ul className="sitemap-links">
                                        <li>
                                            <Link to="#">login</Link>
                                        </li>
                                        <li>
                                            <Link to="#">signup</Link>
                                        </li>
                                        <li>
                                            <Link to="#">recover-password</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="list-col">
                                <div className="title-links">
                                    <h4 className="small-title">About Regenera</h4>
                                    <ul className="sitemap-links">
                                        <li>
                                            <Link to="#">organizations</Link>
                                        </li>
                                        <li>
                                            <Link to="#">people</Link>
                                        </li>
                                        <li>
                                            <Link to="#">about</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="title-links">
                                    <h4 className="small-title">Regenera login</h4>
                                    <ul className="sitemap-links">
                                        <li>
                                            <Link to="#">login</Link>
                                        </li>
                                        <li>
                                            <Link to="#">signup</Link>
                                        </li>
                                        <li>
                                            <Link to="#">recover-password</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="title-links">
                                    <h4 className="small-title">Regenera login</h4>
                                    <ul className="sitemap-links">
                                        <li>
                                            <Link to="#">login</Link>
                                        </li>
                                        <li>
                                            <Link to="#">signup</Link>
                                        </li>
                                        <li>
                                            <Link to="#">recover-password</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SitemapSection>
            <Footer history={history} />
        </Page>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(SiteMapPage));
